import React, { Dispatch, SetStateAction } from "react";
import { yupValidators } from "../../../helpers/yupValidators";
import CustomInputField from "../../CustomHTMLElements/CustomInputField";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useTypedSelector } from "../../../hooks/redux-hooks/useTypedSelector";
import { selectInvestmentCurrentStep } from "./../../../redux/selectors/investmentSelector";
import { useActions } from "../../../hooks/redux-hooks/useActions";
import {
  selectSelectedInvestment,
  selectOfflineInvestment,
  selectOfflineInvestmentCurrency,
} from "../../../redux/selectors/investmentSelector";
import { selectUserWallets } from "../../../redux/selectors/userSelector";
import {
  LOCAL_CURRENCY_CODE,
  USD_CURRENCY_CODE,
} from "../../../helpers/currencies";
import { handleFormatAmount } from "../../../helpers/formatAmount";
import { currenciesSelector } from "../../../redux/selectors/currenciesSelector";

const schema = yup.object().shape({
  investmentAmount: yupValidators.investmentAmount,
});

interface IOfflineInvestmentStepOne {
  // setShowBankDetails: Dispatch<SetStateAction<boolean>>;
  setError: Dispatch<SetStateAction<string>>;
}

const OfflineInvestmentStepOne: React.FC<IOfflineInvestmentStepOne> = ({
  // setShowBankDetails,
  setError,
}) => {
  const currencyList: {
    currencyType: "Foreign" | "Local";
    currencyCode: string;
  }[] = useTypedSelector(currenciesSelector);
  const investmentCurrency = useTypedSelector(selectOfflineInvestmentCurrency);
  const investment = useTypedSelector(selectOfflineInvestment);
  const selectedInvestment = useTypedSelector(selectSelectedInvestment);
  const { lcy_wallet_id, usd_wallet_id } = useTypedSelector(selectUserWallets);
  const currentStep = useTypedSelector(selectInvestmentCurrentStep);
  const { setOfflineInvestmentInfo, setInvestmentType, setInvestmentStep } =
    useActions();

  const {
    register,
    handleSubmit,
    formState: { errors },
    setError: setFormError,
    getValues,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      investmentAmount: investment.amount,
    },
  });

  const minInvestmentAmount: any = Math.min(
    ...selectedInvestment?.interest_ranges.map(
      (range: any) => range.lower_amount
    )
  );
  const maximumInvestmentAmount: any = Math.max(
    ...selectedInvestment?.interest_ranges.map(
      (range: any) => range.upper_amount
    )
  );

  const onSubmit = ({ investmentAmount }: { investmentAmount: number }) => {
    setError("");
    if (investmentAmount < minInvestmentAmount) {
      return setFormError("investmentAmount", {
        type: "manual",
        message: "Amount is not up to minimum investment amount",
      });
    }
    if (investmentAmount > maximumInvestmentAmount) {
      return setFormError("investmentAmount", {
        type: "manual",
        message: "Amount is more than maximum investment amount",
      });
    }

    if (!investmentCurrency) {
      setError("Choose investment currency");
      // Clear error after 3 seconds
      setTimeout(() => setError(""), 3000);
      return;
    }

    if (
      investmentCurrency === LOCAL_CURRENCY_CODE &&
      selectedInvestment.lcy_locked
    ) {
      return setError(
        "Sorry, the target investment amount has been raised for this currency. "
      );
    }

    setOfflineInvestmentInfo({
      ...investment,
      amount: handleFormatAmount(String(investmentAmount)),
    });
    // setShowBankDetails(true);
    setInvestmentStep(currentStep + 1);
  };
  return (
    <div>
      <h5 className="page-subtitle mb-3">{selectedInvestment?.name}</h5>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="mt-4">
          <p className="form-label">
            What kind of Investment do you want to make?
          </p>
          <div className="alignStepOneBoxes">
            {/* Box for Local Currency  */}
            {selectedInvestment?.lcy_product_id && lcy_wallet_id ? (
              <div
                className={`box ${
                  investmentCurrency === currencyList[0].currencyCode
                    ? "active"
                    : ""
                } ${selectedInvestment.lcy_locked && "disabled"}`}
                onClick={() =>
                  setOfflineInvestmentInfo({
                    ...investment,
                    currency: currencyList[0].currencyCode,
                  })
                }
              >
                <div className="currency">
                  {selectedInvestment.currencyCode}
                </div>
                <p className="label">
                  {selectedInvestment.currencyCode} Investment
                </p>
              </div>
            ) : null}
            {/* Box for USD Currency  */}
            {selectedInvestment?.usd_product_id && usd_wallet_id ? (
              <div
                className={`box ${
                  investmentCurrency === USD_CURRENCY_CODE ? "active" : ""
                } ${selectedInvestment.usd_locked && "disabled"}`}
                onClick={() =>
                  setOfflineInvestmentInfo({
                    ...investment,
                    currency: USD_CURRENCY_CODE,
                  })
                }
              >
                <div className="currency">USD</div>
                <p className="label">USD Investment</p>
              </div>
            ) : null}
          </div>
        </div>

        <CustomInputField
          type="number"
          enableSeparator
          defaultValue={getValues("investmentAmount")}
          maxLength={20}
          {...register("investmentAmount")}
          placeholder="e.g. 100,000.00"
          label="Enter Amount"
          errors={errors.investmentAmount}
        />

        <div className="d-flex justify-content-end align-items-center mt-3 flex-wrap">
          <button
            type="button"
            className="btn btn-sm advancly-nobg-btn mr-3"
            onClick={() => setInvestmentType("")}
          >
            Cancel
          </button>
          <button type="submit" className="btn btn-sm advancly-white-btn">
            Next
          </button>
        </div>
      </form>
    </div>
  );
};
export default OfflineInvestmentStepOne;
