import React, { Fragment } from "react";
import styles from "./WalletCard.module.scss";
import { useTypedSelector } from "./../../hooks/redux-hooks/useTypedSelector";
import {
  selectShowBalance,
  selectUserActivationStatus,
  selectUserId,
  selectUserWallets,
} from "./../../redux/selectors/userSelector";
import { guardUserAction } from "./../../helpers/guardUserActions";
import useWalletBalance from "./../../hooks/custom-hooks/useWalletBalance";
import formatMoney from "./../../helpers/formatMoney";
import Loader from "../Loader/Loader.component";
import Alert from "../Alert/Alert.component";
import { errorHandler } from "./../../helpers/errorHandler";
import { CURRENCIES } from "./../../helpers/currencies";
import { useActions } from "./../../hooks/redux-hooks/useActions";
import WithdrawalModal from "./../WithdrawalModal/WithdrawalModal.component";
import { selectWithdrawal } from "./../../redux/selectors/withdrawalSelector";
import { selectWalletTopUp } from "../../redux/selectors/walletTopUpSelector";
import { currenciesSelector } from "../../redux/selectors/currenciesSelector";

interface IWalletCard {
  title: string;
  activeTab: string;
}

const WalletCard: React.FC<IWalletCard> = ({ title, activeTab }) => {
  const currencyList: {
    currencyType: "Foreign" | "Local";
    currencyCode: string;
  }[] = useTypedSelector(currenciesSelector);
  const isActivated = useTypedSelector(selectUserActivationStatus);
  const withdrawalInfo = useTypedSelector(selectWithdrawal);
  const walletTopUpInfo = useTypedSelector(selectWalletTopUp);
  const { lcy_wallet_id, usd_wallet_id } = useTypedSelector(selectUserWallets);
  const funderId = useTypedSelector(selectUserId);
  const showBalance = useTypedSelector(selectShowBalance);
  const walletId =
    activeTab === currencyList[0].currencyCode ? lcy_wallet_id : usd_wallet_id;

  const {
    data: wallet,
    error,
    isLoading,
  } = useWalletBalance({
    walletId,
    funderId,
  });

  const { setWithdrawalInfo, setWalletTopUpInfo } = useActions();

  return (
    <div className={`${styles.card} px-3 py-4`}>
      {error && <Alert message={errorHandler(error)} />}
      <div className="d-flex justify-content-between align-items-center flex-wrap">
        <div>
          <div className={styles.title}>
            <span className="mr-2">{title} </span>
          </div>
          <div className={styles.value}>
            {isLoading ? (
              <Loader />
            ) : error ? null : (
              <Fragment>
                {showBalance
                  ? formatMoney(wallet?.availableBalance, activeTab)
                  : "****"}
              </Fragment>
            )}
          </div>
        </div>
        <div>
          {!isActivated ? (
            <button
              className="btn btn-sm advancly-white-btn mr-2"
              onClick={(e) => {
                guardUserAction(e, isActivated);
              }}
            >
              Withdraw Funds
            </button>
          ) : (
            <button
              className="btn btn-sm advancly-white-btn mr-2"
              onClick={() => {
                setWithdrawalInfo({
                  ...withdrawalInfo,
                  currency: activeTab,
                  walletId,
                  currentStep: 1,
                  amount: "",
                });
              }}
              data-toggle="modal"
              data-target="#withdrawalModal"
            >
              Withdraw Funds
            </button>
          )}

          {/*<button
            className="btn btn-sm advancly-btn"
            data-toggle="modal"
            data-target="#walletModal"
            onClick={() => {
              setWalletTopUpInfo({
                ...walletTopUpInfo,
                currency: activeTab,
                walletId,
                currentStep: 1,
                amount: "",
              });
            }}
          >
            Top-Up Wallet
          </button>*/}
        </div>
      </div>
      <WithdrawalModal />
    </div>
  );
};
export default WalletCard;
