import { Fragment, useEffect, useState } from "react";
import { CURRENCIES } from "../../../helpers/currencies";
import WalletCard from "./../../../components/WalletCard/WalletCard.component";
import TableDataLoading from "./../../../components/TableDataLoading/TableDataLoading.component";
import NoTableData from "./../../../components/NoTableData/NoTableData.component";
import useWalletTransactions, {
  fetchNextWalletTransactions,
} from "./../../../hooks/custom-hooks/useWalletTransactions";
import Alert from "../../../components/Alert/Alert.component";
import { errorHandler } from "./../../../helpers/errorHandler";
import { useTypedSelector } from "./../../../hooks/redux-hooks/useTypedSelector";
import {
  selectUserId,
  selectUserWallets,
} from "../../../redux/selectors/userSelector";
import formatMoney from "./../../../helpers/formatMoney";
import { formatDate } from "./../../../helpers/formatDate";
import { attachClassNameToText } from "../../../helpers/attachClassNameToTag";
import Paginator from "./../../../components/Paginator/Paginator.component";
import { useQueryClient } from "react-query";
import { appInsights } from "../../../components/AppInsight/AppInsight";
import { useActions } from "../../../hooks/redux-hooks/useActions";
import { selectWalletTopUp } from "../../../redux/selectors/walletTopUpSelector";
import { currenciesSelector } from "../../../redux/selectors/currenciesSelector";

export default function Wallets() {
  const currencyList: {
    currencyType: "Foreign" | "Local";
    currencyCode: string;
  }[] = useTypedSelector(currenciesSelector);
  const { lcy_wallet_id, usd_wallet_id } = useTypedSelector(selectUserWallets);
  const [activeTab, setActiveTab] = useState("");
  const [pageNumber, setPageNumber] = useState(1);
  const size = 20;
  const walletId =
    activeTab === currencyList[0]?.currencyCode ? lcy_wallet_id : usd_wallet_id;
  const queryClient = useQueryClient();
  const funderId = useTypedSelector(selectUserId);
  const { setWalletTopUpInfo } = useActions();
  const walletTopUpInfo = useTypedSelector(selectWalletTopUp);

  const { data, error, isLoading } = useWalletTransactions({
    walletId,
    page: pageNumber,
    size,
    funderId,
  });

  useEffect(() => {
    if (currencyList.length > 0) {
      setActiveTab(currencyList[0]?.currencyCode);
    }
  }, [currencyList]);

  useEffect(() => {
    appInsights.trackPageView({ name: "Wallets.tsx", isLoggedIn: true });
  }, []);

  useEffect(() => {
    if (data?.totalCount > pageNumber * size) {
      fetchNextWalletTransactions({
        walletId,
        page: pageNumber,
        size,
        funderId,
        queryClient,
      });
    }
  }, [data, pageNumber, queryClient, walletId, funderId]);

  return (
    <div>
      {error && <Alert message={errorHandler(error)} />}
      <div className="title-align">
        <div className="page-title">Wallets</div>
      </div>

      {/* Currency Tabs  */}
      <div className="alignToggleTabItems">
        <div className="d-flex justify-content-between">
          {currencyList &&
            currencyList?.length > 0 &&
            currencyList.map((item) => (
              <div
                className={`tabItem ${
                  activeTab === item.currencyCode && "activeTab"
                }`}
                onClick={() => setActiveTab(item.currencyCode)}
                key={item.currencyCode}
              >
                {item.currencyCode} Wallet
              </div>
            ))}
        </div>
      </div>

      {/* Wallet Card  */}
      <WalletCard title="Wallet Balance" activeTab={activeTab} />

      {/* Wallet Transactions History */}
      <div className="mt-4">
        <h5 className="page-subtitle">Recent Transactions</h5>
        <div className="table-responsive">
          <table className="table">
            <thead className="bg-lighter-grey">
              <tr>
                <th>Type</th>
                <th>Reference</th>
                <th>{`Amount (${activeTab})`}</th>
                <th>{`Wallet Balance (${activeTab})`}</th>
                <th>Description</th>
                <th>Date</th>
              </tr>
            </thead>
            <tbody>
              {isLoading ? (
                <TableDataLoading />
              ) : !data?.data?.length ? (
                <NoTableData
                  text="You have not made any transactions yet"
                  icon={<i className="ri-arrow-left-right-line"></i>}
                />
              ) : (
                <Fragment>
                  {data?.data?.map((transaction: any) => {
                    const {
                      amount,
                      transactionDate,
                      transactionId,
                      transactionType,
                      transactioncategory,
                      note,
                      runningBalance,
                    } = transaction;
                    return (
                      <tr key={transactionId}>
                        <th>{transactioncategory}</th>
                        <td>{transactionId}</td>
                        <td className={attachClassNameToText(transactionType)}>
                          {formatMoney(amount, activeTab)}
                        </td>
                        <td
                          className={
                            runningBalance > 0 ? "color-green" : "color-red"
                          }
                        >
                          {formatMoney(runningBalance, activeTab)}
                        </td>
                        <td className="text-capitalize">
                          {note || "No Description"}
                        </td>
                        <td>{formatDate(transactionDate)}</td>
                      </tr>
                    );
                  })}
                </Fragment>
              )}
            </tbody>
          </table>
          <Paginator
            size={size}
            page={pageNumber}
            count={data ? data?.totalCount : 0}
            changeCurrentPage={(pageNumber) => setPageNumber(pageNumber)}
          />
        </div>
      </div>
    </div>
  );
}
