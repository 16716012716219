import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { getData, postData } from "../../apis/apiMethods";
import { apiEndpoints } from "../../apis/apiEndpoints";
import { errorHandler } from "../../helpers/errorHandler";
import { yupValidators } from "../../helpers/yupValidators";
import CustomInputField from "../../components/CustomHTMLElements/CustomInputField";
import LandingLayout from "../../components/LandingLayout/LandingLayout.component";
import { ROUTES } from "../../helpers/routes";
import Loader from "../../components/Loader/Loader.component";
import { useActions } from "./../../hooks/redux-hooks/useActions";
import Alert from "./../../components/Alert/Alert.component";
import { add } from "date-fns";
import { useTypedSelector } from "../../hooks/redux-hooks/useTypedSelector";
import { selectUser } from "./../../redux/selectors/userSelector";
import { isUserActivated } from "./../../helpers/isUserActivated";
import { appInsights } from "../../components/AppInsight/AppInsight";
import { setMinimumTopUpAmounts } from "../../redux/actionCreators";
import { selectWalletTopUp } from "../../redux/selectors/walletTopUpSelector";

const schema = yup.object().shape({
  email: yupValidators.email,
  password: yup.string().required("Please enter your password"),
});

const Login = () => {
  const user = useTypedSelector(selectUser);
  const walletTopUpInfo = useTypedSelector(selectWalletTopUp);

  const [passwordType, setPasswordType] = useState("password");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const history = useHistory();
  const {
    setCurrentUser,
    setUserToken,
    resetSignUp,
    setMinimumTopUpAmounts,
    getCurrencies,
  } = useActions();

  useEffect(() => {
    appInsights.trackPageView({ name: "Login.tsx", isLoggedIn: true });
  }, []);

  useEffect(() => {
    localStorage.clear();
  }, []);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = async ({
    email,
    password,
  }: {
    email: string;
    password: string;
  }) => {
    setLoading(true);
    setError("");

    const reqBody = {
      username: email,
      password,
    };

    try {
      const res: any = await postData(apiEndpoints.LOGIN, reqBody);
      const minimumTopUpAmounts = await getData(
        apiEndpoints.GET_MINIMUM_TOP_UP_AMOUNTS
      );
      const isValidUserType =
        res?.roles.includes("funder_user") ||
        res?.roles.includes("funder_admin");
      console.log({
        isValidUserType,
      });
      if (!isValidUserType) {
        setLoading(false);
        return setError("Invalid login credentials.");
      }

      setUserToken(res?.sso_auth_token);

      // Set when session will expire
      const session_timeout = add(new Date(), {
        seconds: res?.expiresIn,
      });

      const {
        data: {
          funder_id,
          funder_type_id,
          profile_completion_status,
          lcy_wallet_id,
          usd_wallet_id,
          biz_name,
          country,
          kyc_resolved_lastname,
          kyc_resolved_firstname,
          phone_no_country_dial_code,
          is_bvn_verified,
        },
      } = await getData(apiEndpoints.USER);

      if (country === "NG" && !is_bvn_verified) {
        localStorage.setItem("bvnalert", "true");
      }
      setMinimumTopUpAmounts({
        ...walletTopUpInfo,
        minimumTopUpAmounts: minimumTopUpAmounts.data,
      });

      setCurrentUser({
        ...user,
        first_name: res?.first_name,
        last_name: res?.last_name,
        email: res?.email,
        isBvnValidated: res?.isBvnValidated,
        isEmailValidated: res?.isEmailVerified,
        role: res?.roles[0],
        lcy_wallet_id,
        usd_wallet_id,
        session_timeout,
        funder_id,
        funder_type_id,
        country,
        phoneNoCountryDialCode: phone_no_country_dial_code,
        activated: isUserActivated({
          profile_completion_status,
        }),
        business_name: biz_name,
        forcePasswordChange: res.firstTimeLogin,
        kyc_resolved_lastname,
        kyc_resolved_firstname,
      });
      getCurrencies();
      setLoading(false);
      history.push(ROUTES.FUNDER);
    } catch (error) {
      setLoading(false);
      setError(errorHandler(error));
      appInsights.trackException({
        exception: error,
        properties: { fileName: "Login" },
      });
    }
  };

  const togglePasswordField = () => {
    if (passwordType === "password") {
      setPasswordType("text");
    } else {
      setPasswordType("password");
    }
  };

  return (
    <LandingLayout>
      <form className="my-5" onSubmit={handleSubmit(onSubmit)}>
        {error && <Alert message={error} />}
        <div className="mb-5">
          <h3 className="h3 font-weight-bold mb-3 color-background-purple">
            Welcome Back.
          </h3>
          <p>Login to manage your account.</p>
        </div>

        <CustomInputField
          type="email"
          maxLength={128}
          placeholder="morty@ricknmorty.com"
          label="Email Address"
          errors={errors.email}
          {...register("email")}
        />
        <CustomInputField
          type={passwordType}
          maxLength={128}
          placeholder="********"
          label="Password"
          errors={errors.password}
          hasActionButton={true}
          actionButtonText={passwordType === "password" ? "show" : "hide"}
          onClickActionButton={togglePasswordField}
          {...register("password")}
        />

        <div className="d-flex justify-content-end mb-3">
          <Link className="color-blue hover-class" to={ROUTES.RESET_PASSWORD}>
            Forgot Password?
          </Link>
        </div>
        <div className="d-flex justify-content-between align-items-center mt-5 flex-wrap">
          <p className="d-flex flex-wrap">
            <span className="text-muted pr-2">Don't have an account? </span>
            <Link
              className="color-blue hover-class"
              to={ROUTES.SIGN_UP}
              onClick={() => {
                resetSignUp();
              }}
            >
              Sign Up
            </Link>
          </p>
          <button
            type="submit"
            className="btn btn-sm advancly-btn"
            disabled={loading}
          >
            Log In
            {loading && <Loader />}
          </button>
        </div>
      </form>
      {/*  Redeploy*/}
    </LandingLayout>
  );
};

export default Login;
